import React from "react"
import ReactHtmlParser from "react-html-parser"
function Privacy() {
  return (
    <>
      <div className="columns" style={{ marginBottom: "40px" }}>
        <div className="column is-2-desktop is-2-tablet is-hidden-mobile"></div>
        <div className="column is-8-desktop is-8-tablet is-12-mobile">
          <p
            style={{
              fontSize: "2.5rem",
              fontWeight: "700",
              borderBottom: "2px solid #fec600",
              marginTop: "15px",
            }}
          >
            Privacy Policy
          </p>
          <p
            style={{
              margin: "12px 0",
            }}
          >
            <strong>Last updated: May 22, 2021</strong>
          </p>
          <div
            className="content"
            style={{
              fontSize: "16px",
              marginTop: "25px",
              //   color: "#333333",
            }}
          >
            <p>
              We respect your privacy. This section of this Agreement describes
              our privacy practices regarding Personal Information we collect
              from individuals, including when you visit our offices, websites
              administered or operated by us, and/or our mobile applications
              (collectively, the “Sites”).
            </p>
            <p>
              If you do not agree with all of the terms of this Agreement
              (including this Privacy Policy), you should not use, browse, or
              otherwise access any of the Sites. By browsing or using any of the
              Sites, you signify your agreement to this Agreement (including
              this Privacy Policy).
            </p>
            <h3 className="title is-4 margin-top-15px">
              Definition of Personal Information
            </h3>
            <p>
              For the purposes of this Agreement, “Personal Information” means
              any information that identifies, relates to, describes, is
              reasonably capable of being associated with, or could reasonably
              be linked, directly or indirectly, with a particular individual or
              household. <br></br>
              <br></br>Personal Information does not include publicly-available
              information from governmental sources. Information that is
              provided in the aggregate or that is de-identified is not Personal
              Information.
            </p>

            <h3 className="title is-4 margin-top-15px">
              Personal Information We May Collect About You – Sources and
              Categories of Information
            </h3>
            <h4 className="title is-5 margin-top-15px">
              Sources of Collection
            </h4>
            <p>
              This section describes the sources from which we may have
              collected Personal Information about you, and which we may
              continue to collect. These sources include, but are not limited
              to: <br></br>
              <br></br>When you visit the Sites or interact with us in other
              ways, such as when you request information about our Service or
              information we provide to the public; visit the Sites; or transmit
              information through the portal we maintain on behalf of our users.
              For example, when you pay fees, request documents and
              certificates, submit other inquiries, call, or otherwise engage
              with us.
            </p>
            <h4 className="title is-5 margin-top-15px">
              Categories of Personal Information Collected
            </h4>
            <p>
              This section describes the categories of Personal Information we
              may collect about you. Included are categories that describe
              employee as well as client data. These categories include, but are
              not limited to:
            </p>
            <ul>
              <li>
                Contact information, such as name, physical addresses, email
                addresses, and other contact information, emergency contacts,
                account number/username, password, and IP addresses.
              </li>
              <li>
                Unique device and online identifiers, such as device IDs,
                browsing history, and other similar identifiers.
              </li>
              <li>
                Protected class information, such as gender, age, disability
                status, citizenship, medical condition, physical or mental
                disability information collected in the employment context (not
                from job applicants). Educational and professional background
                information, such as work histories, employer information, and
                educational history that would be found on a resume.
              </li>
              <li>
                Audio or visual information, such as recordings of your calls to
                us.
              </li>
              <li>
                Information concerning your personal preferences, such as
                preferred travel methods, preferred airport, and other personal
                interests;
              </li>
              <li>
                Internet or other electronic network activity, such as
                information regarding your browsing history, visits to and
                interaction with our Sites, and information about visits to our
                social media accounts.
              </li>
            </ul>
            <h3 className="title is-4 margin-top-15px">Sign On</h3>
            <p>
              You can log in to our site using sign-in services such as google
              or facebook. These services will authenticate your identity and
              provide you the option to share certain personal information with
              us such as your name and email address to pre-populate our sign-up
              form. Services like these also give you the option to post
              information about your activities on our Service to your social
              media profile page to share with others within your network.
            </p>
            <h3 className="title is-4 margin-top-15px">
              How We Use Personal Information We Collect
            </h3>
            <p>
              This section describes the reasons we may have collected and used
              Personal Information and why we may continue to collect and use
              it. These reasons include, but are not limited to:
            </p>

            <h4 className="title is-5 margin-top-15px">Providing Services</h4>
            <p>
              We use your Personal Information to provide a full range of
              services, including without limitation: contacting you and
              responding to your requests and inquiries; facilitating the
              Service; processing payments; responding to requests for
              documentation; providing marketing; providing customer service;
              distributing newsletters and similar materials; improving the
              Sites; and compliance with applicable laws, regulations, or codes
              of practice.
            </p>
            <h4 className="title is-5 margin-top-15px">
              Security and Fraud Prevention and Detection
            </h4>
            <p>
              We use information for security purposes such as fraud prevention
              and detection. We may use Personal Information to enforce our
              rights, for fraud prevention, or to protect our company,
              affiliates, our customers, the Sites, or a third-party website or
              platform.
            </p>
            <h4 className="title is-5 margin-top-15px">
              Compliance with Laws and to Protect Ourselves
            </h4>
            <p>
              We use your Personal Information to support auditing, legal, and
              compliance purposes including responding to court orders or
              subpoenas. We may also share your Personal Information if a
              government agency or investigatory body requests. We may also use
              your Personal Information when we are investigating potential
              fraud or other areas of concern or if we believe in good faith
              that disclosure is legally required or otherwise necessary to
              protect our rights and property, or the rights, property, or
              safety of others.
            </p>
            <h4 className="title is-5 margin-top-15px">
              Marketing and Recruiting
            </h4>
            <p>
              We use Personal Information to inform you about Voyay!ge and the
              services we offer. We may also use your Personal Information to
              send you information about career opportunities.
            </p>
            <h4 className="title is-5 margin-top-15px">
              Employment and Human Relations Functions
            </h4>
            <p>
              We use Personal Information in connection with our hiring,
              employment, and job training activities.
            </p>
            <h4 className="title is-5 margin-top-15px">Communications</h4>
            <p>
              We use Personal Information to communicate with you about your
              account or our relationship with you. We may contact you about
              your use of our Sites. We may contact you about your account or
              feedback. We might also contact you about this Agreement.
            </p>
            <h4 className="title is-5 margin-top-15px">
              Debug and Identify Errors
            </h4>
            <p>
              We use your Personal Information to debug and identify and repair
              errors that impair existing intended functionality.
            </p>
            <h4 className="title is-5 margin-top-15px">
              Statistical Analysis and Research
            </h4>
            <p>
              We may use your Personal Information for statistical analysis and
              research purposes including analyzing performance.
            </p>
            <h4 className="title is-5 margin-top-15px">Other</h4>
            <p>
              We may use your Personal Information as otherwise described or
              permitted by law, for any other purpose as described in this
              Agreement, or as we may notify you. <br></br>
              <br></br>In addition, we may use your Personal Information in the
              aggregate and in a de-identified manner to create case studies,
              and/or to determine residents' demographics and preferences. By
              doing so, we are better able to deliver to you relevant
              information that you may find to be helpful and informative.
            </p>

            <h3 className="title is-4 margin-top-15px">
              Third Parties with Which We May Share Personal Information
            </h3>
            <p>
              We may share Personal Information with third parties for business
              purposes. These third parties include, but are not limited to:
            </p>
            <ul>
              <li>
                Our internet, IT, and telecommunications service providers
              </li>
              <li>
                Our payroll administrators, client relationship management
                services, and payment/payroll processors
              </li>
              <li>
                Service providers such as banks, accountants, and lawyers and
                entities that provide printing, mailing, or communications
                services providing services on our behalf
              </li>
              <li>
                Our analytics partners, such as Google Analytics, for the
                purposes of tracking usage information
              </li>
            </ul>
            <h3 className="title is-4 margin-top-15px">
              We Do Not Sell Your Personal Information
            </h3>
            <p>
              <strong>
                We do not sell your personal information to third parties.
              </strong>{" "}
              Voyay!ge may share your personal information with third parties
              and third parties may collect your personal information. In
              addition, we may also work with third parties such as network
              advertisers to serve ads on the Voyay!ge Site and on third-party
              websites or other media (e.g., social networking platforms), These
              third parties may use cookies, JavaScript, web beacons (including
              clear GIFs), Flash LSOs and other tracking technologies to measure
              the effectiveness of their ads and to personalize advertising
              content to you.
            </p>
            <h3 className="title is-4 margin-top-15px">
              Additional Information Regarding Our Collection, Use, and Sharing
              of Personal Information
            </h3>
            <p>
              We may also collect and share Personal Information with third
              parties to comply with legal obligations; when we believe in good
              faith that an applicable law requires it; at the request of
              governmental authorities or other third parties conducting an
              investigation; to detect and protect against fraud, or any
              technical or security vulnerabilities; to respond to an emergency;
              or otherwise to protect the rights, property, safety, or security
              of our business, third parties, visitors to the Sites, or the
              public. We may also share Personal Information with any person to
              whom we transfer any of our rights or obligations under any
              agreement, or in connection with a sale, merger, or consolidation
              of our business or other transfer of our assets, whether
              voluntarily or by operation of law, or who is otherwise deemed to
              be our successor or transferee.
            </p>
            <h3 className="title is-4 margin-top-15px">Do Not Track</h3>
            <p>
              “Do Not Track” is a privacy preference that users can set in
              certain web browsers. We do not comply with automated browser
              signals regarding tracking mechanisms, which may include "do not
              track" instructions.
            </p>
            <h2 className="title is-3 margin-top-15px">
              California Data Privacy Rights
            </h2>
            <p>
              If you are a California resident, you may have certain rights with
              respect to Personal Information as set forth under California law
              and explained below.
            </p>
            <h4 className="title is-5 margin-top-15px">Shine the Light</h4>
            <p>
              California Civil Code Section 1798.83, also known as the “Shine
              the Light” law, permits California residents to annually request,
              free of charge, information about the Personal Information (if
              any) disclosed to third parties for direct marketing purposes in
              the preceding calendar year. We do not share Personal Information
              with third parties for their own marketing purposes.
            </p>
            <h4 className="title is-5 margin-top-15px">
              CCPA Rights of Individuals
            </h4>
            <p>
              This section addresses rights provided to California residents
              under the California Consumer Privacy Act of 2018 (“CCPA”)
              regarding the Personal Information we collect. Those rights
              include the right to know about the Personal Information we
              collect, the right to request that we delete it, and the right to
              request that we refrain from selling any Personal Information (the
              “CCPA Rights”). The CCPA Rights are described in greater detail
              below.
            </p>
            <h5 className="title is-6 margin-top-15px">
              Your Right To Request Disclosure of Information We Collect and
              Share About You
            </h5>
            <p>
              You can request the following information from us, with respect to
              the Personal Information that we have collected about you in the
              12 months prior to our receipt of your request:
              <ul>
                <li>
                  The categories of Personal Information we have collected about
                  you
                </li>
                <li>
                  The categories of sources from which we collected your
                  Personal Information
                </li>
                <li>
                  The business or commercial purposes for which we collected the
                  Personal Information
                </li>
                <li>
                  The categories of third parties with which we shared your
                  Personal Information
                </li>
                <li>
                  The categories of Personal Information we have disclosed or
                  shared for a business purpose
                </li>
                <li>
                  The specific pieces of Personal Information we collected
                </li>
              </ul>
            </p>
            <h5 className="title is-6 margin-top-15px">
              Your Right To Request Deletion of Personal Information We Have
              Collected From You
            </h5>
            <p>
              Upon request, we will delete the Personal Information we have
              collected about you that is covered by the CCPA, situations where
              the CCPA authorizes us to retain specific information, including
              when it is necessary for us to provide you with a good or service
              that you requested; perform a contract we entered into with you;
              maintain the functionality or security of our systems; or comply
              with or exercise rights provided by the law. The law also permits
              us to retain specific information for our exclusively internal
              use, but only in ways that are compatible with the context in
              which you provided the information to us or that are reasonably
              aligned with your expectations based on your relationship with us.
            </p>

            <h5 className="title is-6 margin-top-15px">
              We Are Service Providers to Our Clients
            </h5>
            <p>
              When we collect and process Personal Information on our clients,
              we act as their “service provider,” as that term is defined in the
              CCPA. We do not disclose, use, or retain any of the Personal
              Information we collect when collecting or processing Personal
              Information on behalf of our clients for any purpose other than
              the specific purpose of performing the services specified in our
              contracts with our clients. This means that the Company does not
              own the Personal Information we collect on behalf of our clients.
            </p>
            <h5 className="title is-6 margin-top-15px">
              Exercising Your Rights and How We Will Respond
            </h5>
            <p>
              To exercise any of the rights above, or to ask a question, you may
              contact us using the contact information provided on our Sites.{" "}
              <br></br>
              <br></br>
              For requests for access or deletion, we will first acknowledge
              receipt of your request within 10 days of receipt of your request.
              We provide a substantive response to your request as soon as we
              can, generally within 45 days from when we receive your request,
              although we may be allowed to take longer to process your request
              in certain jurisdictions or under certain circumstances. If we
              expect your request is going to take us longer than normal to
              fulfill, we will let you know.
            </p>

            <h5 className="title is-6 margin-top-15px">
              Verification of Identity – Access or Deletion Requests
            </h5>
            <p>
              If we are unable to verify your identity with the degree of
              certainty required based on the type of information or action you
              are requesting, we will not be able to respond to the request. We
              will notify you to explain the basis of the denial.
            </p>

            <h5 className="title is-6 margin-top-15px">Authorized Agents</h5>
            <p>
              You may designate an agent to submit requests on your behalf. The
              agent can be a natural person or a business entity that is
              registered with the California Secretary of State. <br></br>
              <br></br> If you would like to designate an agent to act on your
              behalf, you and the agent will need to comply with our agent
              verification process. You will be required to verify your identity
              by providing us with certain Personal Information as described
              above, depending on whether you hold an account with us or not and
              the nature of the information you require, which we will endeavor
              to match the information submitted to information we maintain
              about you. Additionally, we will require that you provide us with
              written confirmation that you have authorized the agent to act on
              your behalf, and the scope of that authorization. The agent will
              be required to provide us with proof of the agency relationship,
              which may be a declaration attesting to the agent’s identity and
              authorization by you to act on their behalf, signed under penalty
              of perjury. If the agent is a business entity, it will also need
              to submit evidence that it is registered and in good standing with
              the California Secretary of State. Information to identify and
              verify your agent can be submitted through the same mechanism and
              at the same time that you submit information to verify your
              identity.<br></br>
              <br></br> Please note that this subsection does not apply when an
              agent is authorized to act on your behalf pursuant to a valid
              power of attorney. Any such requests will be processed in
              accordance with California law pertaining to powers of attorney.
            </p>
            <h5 className="title is-6 margin-top-15px">
              Requests for Household Information
            </h5>
            <p>
              There may be some types of Personal Information that can be
              associated with a household (a group of people living together in
              a single dwelling). Requests for access or deletion of household
              Personal Information must be made by each member of the household.
              We will verify each member of the household using the verification
              criteria explained above. <br></br>
              <br></br>If we are unable to verify the identity of each household
              member with the degree of certainty required, we will not be able
              to respond to the request. We will notify you to explain the basis
              of our denial.
            </p>

            <h2 className="title is-3 margin-top-15px">GDPR Privacy</h2>
            <h3 className="title is-4 margin-top-15px">
              Legal Basis for Processing Personal Data under GDPR
            </h3>
            <p>We may process Personal Data under the following conditions:</p>
            <div className="content">
              <ul>
                <li>
                  <strong>Consent:</strong> You have given Your consent for
                  processing Personal Data for one or more specific purposes.
                </li>
                <li>
                  <strong>Performance of a contract:</strong> Provision of
                  Personal Data is necessary for the performance of an agreement
                  with You and/or for any pre-contractual obligations thereof.
                </li>
                <li>
                  <strong>Legal obligations:</strong> Processing Personal Data
                  is necessary for compliance with a legal obligation to which
                  the Company is subject.
                </li>
                <li>
                  <strong>Vital interests:</strong> Processing Personal Data is
                  necessary in order to protect Your vital interests or of
                  another natural person.
                </li>
                <li>
                  <strong>Public interests:</strong> Processing Personal Data is
                  related to a task that is carried out in the public interest
                  or in the exercise of official authority vested in the
                  Company.
                </li>
                <li>
                  <strong>Legitimate interests:</strong> Processing Personal
                  Data is necessary for the purposes of the legitimate interests
                  pursued by the Company.
                </li>
              </ul>
            </div>
            <p>
              In any case, the Company will gladly help to clarify the specific
              legal basis that applies to the processing, and in particular
              whether the provision of Personal Data is a statutory or
              contractual requirement, or a requirement necessary to enter into
              a contract.
            </p>

            <h3 className="title is-4 margin-top-15px">
              Your Rights under the GDPR
            </h3>
            <p>
              The Company undertakes to respect the confidentiality of Your
              Personal Data and to guarantee You can exercise Your rights.
            </p>
            <p>
              You have the right under this Privacy Policy, and by law if You
              are within the EU, to:
            </p>
            <div className="content">
              <ul>
                <li>
                  <strong>Request access to Your Personal Data.</strong> The
                  right to access, update or delete the information We have on
                  You. Whenever made possible, you can access, update or request
                  deletion of Your Personal Data directly within Your account
                  settings section. If you are unable to perform these actions
                  yourself, please contact Us to assist You. This also enables
                  You to receive a copy of the Personal Data We hold about You.
                </li>
                <li>
                  <strong>
                    Request correction of the Personal Data that We hold about
                    You.
                  </strong>{" "}
                  You have the right to to have any incomplete or inaccurate
                  information We hold about You corrected.
                </li>
                <li>
                  <strong>Object to processing of Your Personal Data.</strong>{" "}
                  This right exists where We are relying on a legitimate
                  interest as the legal basis for Our processing and there is
                  something about Your particular situation, which makes You
                  want to object to our processing of Your Personal Data on this
                  ground. You also have the right to object where We are
                  processing Your Personal Data for direct marketing purposes.
                </li>
                <li>
                  <strong>Request erasure of Your Personal Data.</strong> You
                  have the right to ask Us to delete or remove Personal Data
                  when there is no good reason for Us to continue processing it.
                </li>
                <li>
                  <strong>Request the transfer of Your Personal Data.</strong>{" "}
                  We will provide to You, or to a third-party You have chosen,
                  Your Personal Data in a structured, commonly used,
                  machine-readable format. Please note that this right only
                  applies to automated information which You initially provided
                  consent for Us to use or where We used the information to
                  perform a contract with You.
                </li>
                <li>
                  <strong>Withdraw Your consent.</strong> You have the right to
                  withdraw Your consent on using your Personal Data. If You
                  withdraw Your consent, We may not be able to provide You with
                  access to certain specific functionalities of the Service.
                </li>
              </ul>
            </div>

            <h3 className="title is-4 margin-top-15px">
              Exercising of Your GDPR Data Protection Rights
            </h3>
            <p>
              You may exercise Your rights of access, rectification,
              cancellation and opposition by contacting Us. Please note that we
              may ask You to verify Your identity before responding to such
              requests. If You make a request, We will try our best to respond
              to You as soon as possible.
            </p>
            <p>
              You have the right to complain to a Data Protection Authority
              about Our collection and use of Your Personal Data. For more
              information, if You are in the European Economic Area (EEA),
              please contact Your local data protection authority in the EEA.
            </p>

            <h3 className="title is-4 margin-top-15px">
              Our Commitment to Honoring Your Rights
            </h3>
            <p>
              If you exercise any of your rights described in this Agreement,
              including any CCPA Rights you may have, we will continue to treat
              you fairly and you will not be denied or charged different prices
              or rates for goods or services, or provided a different level or
              quality of goods or services than others.
            </p>

            <h3 className="title is-4 margin-top-15px">
              Personal Information of Minors
            </h3>
            <p>
              Our Sites are not intended for children under the age of 18. We do
              not knowingly collect or sell Personal Information of children
              under 18. To protect their privacy, children under the age of 18
              are prohibited from using any of our Sites.
            </p>
            <h3 className="title is-4 margin-top-15px">Third Party Sites</h3>
            <p>
              From time to time, we may advertise certain third party services
              through our Sites which we feel will be helpful or relevant to you
              (collectively, “Third Party Services”).<br></br>
              <br></br> When you click on a link for a Third Party Service, your
              web browser will be re-directed to the third party provider of the
              Third Party Service that you selected. Third party providers may
              have privacy policies that are different than this Agreement, and
              you should read carefully the privacy policies of those vendors
              prior to releasing any of your information to them, financial or
              otherwise.<br></br>
              <br></br> Third Party Services may also require you to agree to
              certain terms and conditions of use (collectively, “Third Party
              Conditions”) before using or accessing those services. Often,
              Third Party Conditions describe and govern how third party
              providers use your Personal Information, so you should read
              carefully all Third Party Conditions prior to releasing any of
              your information to such third party sites. <br></br>
              <br></br>We do not endorse, warrant, or guaranty the nature,
              scope, quantity, availability, or quality of any Third Party
              Services. We make you aware of such services solely for your
              convenience. If you have any questions, comments, or concerns
              about any Third Party Service, you are instructed to contact
              directly the vendor offering the service.
            </p>
            <h3 className="title is-4 margin-top-15px">Security</h3>
            <p>
              We implement and maintain reasonable security appropriate to the
              nature of the Personal Information that we collect, use, retain,
              transfer, or otherwise process. Our reasonable security program is
              implemented and maintained in accordance with applicable law and
              relevant standards; however, there is no perfect security, and
              reasonable security is a process that involves risk management
              rather than risk elimination. While we are committed to
              developing, implementing, maintaining, monitoring, and updating a
              reasonable information security program, no such program can be
              perfect; in other words, all risk cannot reasonably be eliminated.
              Data security incidents and breaches can occur due to
              vulnerabilities, criminal exploits, or other factors that cannot
              reasonably be prevented. Accordingly, while our reasonable
              security program is designed to manage data security risks and
              thus help prevent data security incidents and breaches, it cannot
              be assumed that the occurrence of any given incident or breach
              results from our failure to implement and maintain reasonable
              security.
            </p>
            <h3 className="title is-4 margin-top-15px">Accuracy & Access</h3>
            <p>
              Your Personal Information is accurate to the extent that you
              provide it correctly and accurately to us or to the party
              collecting such information. Errors that you make when entering
              your Personal Information may result in erroneous information in
              our database. We provide the ability to review and/or correct
              information. <br></br>
              <br></br>To protect your privacy and security, we may take
              reasonable steps to verify your identity, such as requiring a
              password and user ID, before we permit any changes to be made to
              the Personal Information in our database. To view and change the
              Personal Information that you directly provided to us, contact us
              using the information provided on our Sites.
            </p>

            <h3 className="title is-4 margin-top-15px">
              Changes to this Agreement
            </h3>
            <p>
              We reserve the right to amend, alter, or otherwise change this
              Agreement in our sole and absolute discretion.
            </p>

            <h1 className="title is-2 margin-top-15px">Contact Us</h1>
            <p>
              If you have any questions about this Privacy Policy, You can
              contact us:
            </p>
            <ul>
              <li>
                By email:{" "}
                <a
                  className="link"
                  href="mailto: reach-out@voyayge.com"
                  //   style={{ textDecoration: "none", color: "#4a4a4a" }}
                >
                  reach-out@voyayge.com
                </a>
              </li>
              <li>
                By visiting this page on our website:{" "}
                <a
                  className="link"
                  href="https://www.voyayge.com/contact"
                  //   style={{ textDecoration: "none", color: "#4a4a4a" }}
                >
                  https://www.voyayge.com/contact
                </a>
              </li>
            </ul>
            <p>
              When contacting us please be sure to provide your name, your
              physical address, your email address, your phone number (if you
              wish to be contacted by telephone), and a description of the
              question or issue that you wish to have resolved.
            </p>
          </div>
        </div>
        <div className="column is-2-desktop is-2-tablet is-hidden-mobile"></div>
      </div>
    </>
  )
}

export default Privacy
