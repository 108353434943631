import React, { Fragment } from "react"
import Layout from "../components/layout"
import Privacy from "../components/Privacy/Privacy"
import SEO from "../components/seo"

const PrivacyPage = () => {
  return (
    <Fragment>
      <Privacy />
    </Fragment>
  )
}

export default () => (
  <Layout>
    <SEO pageTitle="Privacy Statement" description="Our Privacy Policy" />
    <PrivacyPage />
  </Layout>
)
